import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { BookingDetailsDataHook } from './dataHooks';
import { classes, st } from './BookingDetails.st.css';
import { TagName } from '../../../../utils/tagName.const';
import { Service } from '../../../../utils/mappers/service.mapper';
import VideoConferenceBadge from '../VideoConferenceBadge/VideoConferenceBadge';
import Duration from '../Duration/Duration';
import StaffMembers from '../StaffMembers/StaffMembers';
import Location from '../Location/Location';
import DateAndTime from '../DateAndTime/DateAndTime';
import Text, { TextType } from '../../Text/Text';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import settingsParams from '../../settingsParams';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import { getContent } from '../../../../utils/content/content';

export interface BookingDetailsProps {
  service: Service;
  startDate: string;
  endDate: string;
  dateRegionalSettingsLocale: string;
  timezone?: string;
}

const BookingDetails: React.FC<BookingDetailsProps> = ({
  service,
  startDate,
  endDate,
  timezone,
  dateRegionalSettingsLocale,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  const sectionTitle = getContent({
    settings,
    settingsParam: settingsParams.summarySectionTitle,
  });

  return (
    <div className={st(classes.root, { isMobile })}>
      <SectionTitle label={sectionTitle} tagName={TagName.SecondaryHeading} />
      <VideoConferenceBadge
        videoConferenceProviderId={service.videoConferenceProviderId}
      />
      <Text
        type={TextType.Primary}
        data-hook={BookingDetailsDataHook.SERVICE_NAME}
        tagName={TagName.Paragraph}
      >
        {service.name}
      </Text>
      <DateAndTime
        totalNumberOfSessions={service.totalNumberOfSessions}
        startDate={startDate}
        timezone={timezone}
        dateRegionalSettingsLocale={dateRegionalSettingsLocale}
      />
      <Duration
        startDate={startDate!}
        endDate={endDate!}
        totalNumberOfSessions={service.totalNumberOfSessions}
      />
      <StaffMembers staffMembers={service.staffMembers} />
      <Location location={service.location} />
    </div>
  );
};

export default BookingDetails;
