import React from 'react';
import { PaymentTypeDataHooks } from './dataHooks';
import {
  TFunction,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { classes, st } from './PaymentTypeSelection.st.css';
import { useFormActions } from '../../../Hooks/useFormActions';
import { Dropdown } from 'wix-ui-tpa/Dropdown';
import { PaymentType } from '../../../../../utils/mappers/service.mapper';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { getContent } from '../../../../../utils/content/content';
import settingsParams from '../../../settingsParams';
import { ISettingsContextValue } from '@wix/yoshi-flow-editor/tpa-settings';
import Text from '../../../Text/Text';
import { TagName } from '../../../../../utils/tagName.const';

export type PaymentTypeSelectionProps = {
  selectedPaymentType: PaymentType;
  paymentTypes: PaymentType[];
};

export const PaymentTypeSelection: React.FC<PaymentTypeSelectionProps> = ({
  selectedPaymentType,
  paymentTypes,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { setSelectedPaymentType } = useFormActions();
  const dropdownOptions = paymentTypes.map((paymentType) => ({
    id: paymentType,
    value: mapTypeToDropdownValue(paymentType, t, settings),
    isSelectable: true,
  }));
  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook={PaymentTypeDataHooks.Payment_Type_Selection}
    >
      <Text
        className={classes.label}
        data-hook={PaymentTypeDataHooks.Payment_Type_Selector_Label}
        tagName={TagName.Span}
      >
        {getContent({
          settings,
          settingsParam: settingsParams.selectPaymentTypeLabel,
        })}
      </Text>
      <Dropdown
        upgrade
        className={classes.PaymentTypeSelector}
        initialSelectedId={selectedPaymentType}
        options={dropdownOptions}
        onChange={(option) => setSelectedPaymentType(option.id as PaymentType)}
        data-hook={PaymentTypeDataHooks.Dropdown}
      />
    </div>
  );
};

const mapTypeToDropdownValue = (
  type: PaymentType,
  t: TFunction,
  settings: Partial<ISettingsContextValue>,
): string => {
  const settingsParam =
    type === PaymentType.ONLINE
      ? settingsParams.payOnlineText
      : settingsParams.payOfflineText;
  return getContent({
    settings,
    settingsParam,
  });
};
